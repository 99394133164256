import Inputmask from "inputmask";
import $ from 'jquery';
import slider from './modules/swiper';


window.addEventListener('DOMContentLoaded', () => {

    try {
        slider({
            swiperEl: '.partners__swiper', 
            paginationEl: ".partners-pagination", 
            arrPrev: ".partners-button-prev",
            arrNext: ".partners-button-next", 
        });
    } catch(e) {
        // console.log(e);
    }

    try {
        if(window.innerWidth < 780) {
            slider({
                    swiperEl: '.statistic_swiper', 
                    numberToShow: "auto", 
                    isLoop: true, 
                });
        }
        
    } catch(e) {
        // console.log(e);
    }

    
    try {
        const width = window.innerWidth < 440 ? 1.1 : "auto";

        if(window.innerWidth < 780) {
            slider(
                {
                    swiperEl: '.adv__cards', 
                    numberToShow: width, 
                    isLoop: true, 
                    spaceBetween: 16
                });
        }
        
    } catch(e) {
        // console.log(e);
    }

    try {

        const widthRew = window.innerWidth > 450 ? 1.4 : 1;
        slider({
            swiperEl: '.reviews__swiper', 
            paginationEl: ".reviews-pagination", 
            arrPrev: ".reviews-button-prev",
            arrNext: ".reviews-button-next", 
            numberToShow: widthRew,
            spaceBetween: 8
        });
    } catch(e) {
        // console.log(e);
    }

    try {
        if(window.innerWidth > 1020) {
            const inputs = document.querySelectorAll('input');

            inputs.forEach(item => {
                switch (item.getAttribute('name')) {
                    case 'firstname': return item.setAttribute('placeholder', 'Константин');
                    case 'lastname':  return  item.setAttribute('placeholder', 'Константинов');
                    case 'phone':  return item.setAttribute('placeholder', '+7 900 000 00 00');
                    case 'email':  return item.setAttribute('placeholder', 'name@askona.ru');
                    case 'company':  return item.setAttribute('placeholder', 'Аskona');
                    case 'site':   return item.setAttribute('placeholder', 'askona.ru');
                        
                }
            })
        }
        
    } catch(e) {
        // console.log(e);
    }

    try {
        function sentMail() {
            const successMess = document.querySelector('.success_mess');
            const errMess = document.querySelector('.err_mess');

            function showMess(messEl) {
                messEl.classList.remove('hide');
            }

            function removeMess(messEl) {
                messEl.classList.add('hide');
            }

            removeMess(successMess);
            removeMess(errMess);



            $("[data-order_form]").on("submit", function (t) {
                t.preventDefault()
                let phone, firstname, lastname, email, company, site,  country, comment;
                firstname = $(this).find('#firstname').val()
                lastname = $(this).find('#lastname').val()
                phone = $(this).find('#phone').val()
                email = $(this).find('#email').val()
                company = $(this).find('#company').val()
                site = $(this).find('#site').val()
                country =  $(this).find('#country').val()
                comment = $(this).find('#comment').val() 
                $.ajax({
                    url: "sendmail.php",
                    method: "GET",
                    data: {
                        firstname: firstname,
                        lastname: lastname,
                        phone: phone,
                        email: email,
                        company: company,
                        site: site,
                        country: country,
                        comment: comment
                    },
                    success: function (t) {
                        removeMess(errMess);
                        try{ $("[data-order_form]").trigger("reset")} catch(e) {console.log(e)}
                        showMess(successMess);
                    },
                    error: function (t) {
                        removeMess(successMess);
                        showMess(errMess);
                    }
                })
            })
        }

        sentMail();
        
    } catch(e) {
        console.log(e);
    } 

    

    try {
        const telInput = document.querySelector("#phone");
        var im = new Inputmask("+7 (999) 999-9999");
        im.mask(telInput);
    } catch(e) {
        console.log(e);
    } 

})