 // import Swiper JS
 import Swiper, { Navigation, Pagination } from 'swiper';

const slider = ({swiperEl, paginationEl = '', arrPrev = '', arrNext = '', numberToShow = 1, isLoop = false, spaceBetween = 0}) => {
    const swiper = new Swiper(swiperEl, {
        slidesPerView: numberToShow,
        spaceBetween: spaceBetween,
        
        navigation: {
          nextEl: arrNext,
          prevEl: arrPrev,
        },
        modules: [ Navigation, Pagination ],
        pagination: {
            el: paginationEl,
            clickable: true,
        },
        loop: isLoop, 
        autoHeight: false,
    });

    swiper();
}

export default slider;